/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// react plugin used to create charts
// import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from 'reactstrap'
// core components
import Tables from './Tables'

import AWS from 'aws-sdk'
import GoDaddy from 'godaddy-api'
import config from '../config.json'

class Dashboard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidMount() {
        this.awsDomains()
        this.goDaddyDomains()
    }

    awsDomains = () => {
        for (const business of ['Clifton', 'ABF']) {
            AWS.config.update(config[business.toLowerCase()])
            AWS.config.update({region: 'us-east-1'})
            const route53domains = new AWS.Route53Domains()

            route53domains.listDomains({}, function (err, data) {
                if (err) {
                    console.log(err, err.stack)
                } else {
                    console.log(`AWS ${business} domains:`)
                    console.log(data)
                }
            })
        }
    }

    goDaddyDomains = () => {
        const godaddy = GoDaddy(config.goDaddy.key, config.goDaddy.secret)

        godaddy.domains.tlds().then(function (res) {
            const
                tlds = res.body

            console.log('TLDs:')
            console.log(tlds)
        })

        const
            authorisation = `sso-key ${config.goDaddy.key}:${config.goDaddy.secret}`,
            endpoint = 'https://api.godaddy.com/v1/domains/tlds',
            myHeaders = new Headers()

        myHeaders.append('Authorization', authorisation)
        // myHeaders.append('Access-Control-Allow-Origin', 'https://debian2:3000')
        myHeaders.append('Accept', 'application/json')
        myHeaders.append('Content-Type', 'application/json')

        const
            myRequest = new Request(endpoint, {
                method: 'GET',
                credentials: 'include',
                headers: myHeaders,
            })

        fetch(myRequest)
            .then(response => {
                const contentType = response.headers.get('content-type')
                if (!contentType || !contentType.includes('application/json')) {
                    throw new TypeError('Oops, we haven\'t got JSON!')
                }
                return response.json()
            })
            .then(data => {
                console.log(data)
            })
            .catch(error => console.error(error))
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="fas fa-home"/>
                                            </div>
                                        </Col>
                                        <Col md="9" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Domain Registrations</p>
                                                <CardTitle tag="p">75</CardTitle>
                                                <p/>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr/>
                                    <div className="stats">
                                        <i className="fas fa-exclamation-circle"/> 3 not auto-renewing
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="fas fa-lock"/>
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">SSL Certificates</p>
                                                <CardTitle tag="p">125</CardTitle>
                                                <p/>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr/>
                                    <div className="stats">
                                        <i className="fas fa-exclamation-circle"/> Help!
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <Tables/>
                </div>
            </>
        )
    }
}

export default Dashboard
